import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import robot_img from '../assets/images/robot.png';
export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="grid gap-y-6 xl:gap-y-8">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Not Found</title>
        <meta
          name="description"
          content="404 Error! Not found!"
        />
      </Helmet>
      <section className="flex flex-col px-8 max-md:mt-6">
        <div className="flex flex-col justify-center">
          <p className="font-bold md:text-[20px] xl:text-[24px] text-[#4387EB] text-center">
            404. That’s an error.
          </p>
          <p className="text-sm md:text-base xl:text-base text-center">
            The requested URL /error was not found on this server. That’s all we know.
          </p>
        </div>
        <div className="flex justify-center">
          <img
            src={robot_img}
            alt=""
            className="xl:w-[400px] md:w-[50vw] w-[75vw]"
          />
        </div>
        <div className="flex justify-center">
          <button
            className="transition ease-in-out hover:bg-[#383231] hover:border-[#383231] hover:text-white px-5 py-2 bg-[#2E90F2] rounded-lg max-md:w-full text-white xl:text-base border-[#2E90F2] border"
            onClick={() => {
              navigate("/");
            }} 
          >
            Go to Home
          </button>
        </div>
      </section>
    </div>
  );
};
