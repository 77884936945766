import { WhiteBtn } from "../components/LearnMore";
import { CenterDesc } from "../components/CenterDesc";
import { FitItem } from "../components/FitItem";
import ar_log from "../assets/images/ar_log_img.png";
import vr_log from "../assets/images/vr_log_img.png";
import vr_community from "../assets/images/ar_arm_tapped.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VideoBox } from "../components/VideoBox";
import { HeroWrap } from "../components/HeroWrap";
import csr_poster from "../assets/images/csr-corporate-social.webp";
import check_icon from "../assets/images/basic/check_bold.svg";
import product_img from "../assets/images/product_visualization.png";
import maintence_trouble from "../assets/images/maintenance_trouble.webp";
import driving_training from "../assets/images/driver_training.png";
import damage_img from "../assets/images/damage_img.webp";
import simulate_img from "../assets/images/dron_img.webp";
import Scrollbar from "../components/scrollbar";
import mobile_hero from "../assets/images/home_mobile_hero.png";
import { Helmet } from "react-helmet";
import {
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { backend_baseUrl } from "./Contact";
import { useForm } from "react-hook-form";
import { InquiryResultModal } from "../components/InquiryResultModal";
import { showCaseStudy } from "../store";
import { useRecoilState } from "recoil";
import { HeaderBtn } from "../components/layouts/Header";

export type projectContactType = {
  email: string;
  case_kind: string;
  content: string;
};
export const Home = () => {
  const [eduType, setEduType] = useState("VR/AR");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [showCase, setShowCase] = useRecoilState(showCaseStudy);
  const navigate = useNavigate();
  const {
    register,
    getValues,
    watch,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
  } = useForm<projectContactType>();
  const { content } = watch();
  const nonEmpty = content ? content?.trim().length > 0 : false;
  const sendInquiry = async () => {
    if (backend_baseUrl) {
      const data = getValues();
      const url = `${backend_baseUrl}/api/contact/mmc_send_inquiry`;
      setIsSending(true);
      try {
        await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        setIsSuccess(true);
      } catch (ex) {
        setIsSuccess(false);
      }
      setIsSending(false);
      setShowMessage(true);
    }
  };

  return (
    <div className="grid gap-y-6 xl:gap-y-8">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | ARSOME Technology</title>
        <meta
          name="description"
          content="ARSOME Technology develops augmented, &amp; mixed reality programs and products for businesses. Start a project today."
        ></meta>
      </Helmet>
      <HeroWrap className="max-md:hidden md:main_home_bg">
        <>
          <p className="font-bold md:text-[20px] xl:text-[32px]">
            Tailor-Made AR/VR Training
          </p>
          <p className="text-sm md:text-base xl:text-base">
            Our AR/VR solutions are second to none. It's our tailor-made
            approach, AI integration, and ability to develop enterprise wide
            solutions that sets us. It's why the most important organizations on
            the planet rely on our immersive AR/VR training, like the U.S.
            Department of Defense.
          </p>
          <div className="space-x-3">
            <WhiteBtn
              title="Learn More"
              onAction={() => {
                navigate("/ar_vr_custom_development");
              }}
            />
            <WhiteBtn
              title="Case Study"
              onAction={() => {
                setShowCase(true);
              }}
            />
          </div>
        </>
      </HeroWrap>
      <div className="max-md:block md:hidden">
        <HeroWrap className="max-md:block md:hidden max-md:border-y max-md:border-[#2E90F2]">
          <p className="font-bold text-xl text-[#2E90F2] text-left">
            Personalized AR/VR Training
          </p>
          <p className=" font-light text-left text-[#383231] mt-4">
            We don’t create AR/VR just to be awesome. There’s purpose behind
            everything we do so that our clients have the most advanced training
            programs to train and equip their employees. Our AR/VR technology
            and vocational expertise are second to none, but it's our
            personalized development, integration of AI, and commitment to
            exceeding client expectations that set us. It's why the most
            important organizations rely on our AR/VR, like the Department of
            Defense.
          </p>
          <div className="flex mt-4 space-x-6 max-xl:justify-center">
            <button
              className="px-5 py-2 bg-[#2E90F2] rounded-lg max-md:w-full text-white xl:text-base border-[#2E90F2] border"
              onClick={() => {
                setShowCase(true);
              }}
            >
              Case Study
            </button>
            <button
              className="px-5 py-2 bg-white rounded-lg max-md:w-full text-[#2E90F2] xl:text-base border-[#2E90F2] border"
              onClick={() => {
                navigate("/ar_vr_custom_development");
              }}
            >
              Learn More
            </button>
          </div>
          <div className="flex justify-center mt-4">
            <img src={mobile_hero} alt="" />
          </div>
        </HeroWrap>
      </div>
      <CenterDesc
        title="Tailor-Made Training & Development"
        description="After launching in 2017, we’ve developed 100+ custom AR/VR training programs for defense, 
        manufacturing, and aerospace organizations. Our platforms allow us to design simulations quicker, 
        better, and smarter. Our people are industry experts and align each training program to best practices and military standards."
      />
      <section className="w-full relative max-sm:min-h-[390px] xl:h-auto">
        <div className="md:py-4 xl:py-8 flex xl:flex-row max-xl:flex-col justify-center max-md:space-y-4 xl:w-[1334px] xl:mx-auto xl:justify-between md:space-y-6 xl:space-x-0">
          <div className="flex justify-center">
            <VideoBox
              className="w-[350px] md:w-[70vw] xl:w-[634px] min-h-[250px]"
              url="https://youtu.be/wBgskfrBqyA"
            />
          </div>
          <div className="flex items-center justify-center md:justify-center xl:w-[678px]">
            <div className="p-8 md:p-8 xl:p-0 md:w-[90vw] xl:w-[670px] grid md:gap-y-4 xl:gap-y-8 gap-y-4">
              <div className="grid gap-y-4 xl:gap-y-6">
                <h6 className="text-xl md:text-center xl:text-start font-bold xl:text-2xl text-[#383231] max-md:text-start">
                  AR Parts + Pieces Platform
                </h6>
                <p className="text-[#383231] font-light md:text-center xl:text-start">
                  Teach employees how to identify parts and pieces of complex
                  machines, engines, vehicles, weaponry, etc., by removing
                  layers and simulating processes. Improve employee competency
                  and readiness around system troubleshooting, part maintenance,
                  and part replacement by leveraging the benefits of our
                  proprietary and internal AR Parts + Pieces Platform.
                  Supervisors receive data-driven insights powered by artificial
                  intelligence to assist with measuring and correcting personnel
                  performance over time.
                </p>
                <div className="md:flex xl:space-x-6 md:space-x-6 max-md:space-y-3 md:flex-row md:justify-center xl:justify-start">
                  <div className="flex space-x-1 max-md:justify-start">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#2E90F2] font-bold">
                        Increased Readiness
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-1 max-md:justify-start">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#2E90F2] font-bold">
                        Increased Safety
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-1 max-md:justify-start">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#2E90F2] font-bold">
                        Predictive Maintenance
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex space-x-6 max-xl:justify-center">
                <button
                  className="transition ease-in-out hover:bg-[#383231] hover:border-[#383231] hover:text-white px-5 py-2 bg-[#2E90F2] rounded-lg max-md:w-full text-white xl:text-base border-[#2E90F2] border"
                  onClick={() => {
                    setShowCase(true);
                  }}
                >
                  Case Study
                </button>
                <button
                  className="transition ease-in-out hover:bg-[#383231] hover:text-white hover:border-[#383231] px-5 py-2 bg-white rounded-lg max-md:w-full text-[#2E90F2] xl:text-base border-[#2E90F2] border"
                  onClick={() => {
                    navigate("/ar_vr_custom_development");
                  }}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="md:ar_vr_sec_bg md:w-full xl:min-h-[384px] 2xl:min-h-[447px] md:h-[266px] md:flex md:justify-center md:items-center max-md:border max-md:border-[#FCDC3E] max-md:border-x-0">
        <div className="grid p-8 md:bg_workforce md:text-white md:w-9/12 xl:w-[1200px] md:rounded-xl gap-y-4">
          <div className="text-center xl:text-2xl max-md:font-light">
            We bring 2D training and maintenance manuals to life with AR/VR
            instruction to improve your training outcome. Digital training
            manuals are the new standard if you’re working in the defense
            sector. Bring your manual to life with ARSOME Technology.
          </div>
          <div className="flex justify-center">
            <button
              className="transition ease-in-out hover:bg-[#383231] hover:text-white max-xl:text-md text-[#383231] max-md:text-white bg-white px-4 py-2 rounded-lg max-md:w-[50vw] max-md:blue_btn text-base"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Book Demo
            </button>
          </div>
        </div>
      </section>
      <section className="w-full max-sm:min-h-[390px] xl:h-auto">
        <div className="md:py-4 xl:py-8 flex flex-col-reverse md:flex-col-reverse xl:flex-row justify-center max-md:space-y-4 xl:w-[1334px] xl:mx-auto xl:justify-between md:space-y-6 xl:space-x-0">
          <div className="flex items-center justify-center">
            <div className="p-8 md:p-8 xl:p-0 md:w-[90vw] text-white xl:w-[600px] grid gap-y-4 xl:gap-y-8 md:mx-auto">
              <div className="grid gap-y-6">
                <h6 className="text-xl font-bold xl:text-2xl text-[#383231] text-start md:text-center xl:text-start max-md:mt-4 md:mt-4">
                  VR Simulation Platform
                </h6>
                <p className=" text-[#383231] font-light text-start md:text-center xl:text-start">
                  Prepare employees for workplace activities or tasks that are
                  dangerous, time-sensitive, or expensive by having them
                  practice in a safe and controlled simulated environment.
                  Improve workplace safety, accuracy, and readiness to ensure
                  employees are prepared for the real thing by leveraging the
                  benefits of our proprietary and internal VR Simulation
                  Platform. Multiplayer is available so supervisors can observe
                  and correct employee performance in real-time and assign
                  learning modules to overcome specific weaknesses.
                </p>
                <div className="md:flex xl:space-x-3 max-md:space-y-2 md:flex-row md:justify-center md:space-x-6 xl:justify-start">
                  <div className="flex space-x-1 ">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#2E90F2] font-bold ">
                        Extremely Realistic
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-1 ">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#2E90F2] font-bold ">
                        Data-driven Insights
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-1">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#2E90F2] font-bold ">
                        Immediate ROI
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex space-x-6 max-xl:justify-center ">
                <button
                  className="transition ease-in-out hover:bg-[#383231] hover:text-white hover:border-[#383231] px-5 py-2 bg-[#2E90F2] rounded-lg max-md:w-full text-white xl:text-base border-[#2E90F2] border"
                  onClick={() => {
                    setShowCase(true);
                  }}
                >
                  Case Study
                </button>
                <button
                  className="transition ease-in-out hover:bg-[#383231] hover:text-white hover:border-[#383231] px-5 py-2 bg-white rounded-lg max-md:w-full text-[#2E90F2] xl:text-base border-[#2E90F2] border"
                  onClick={() => {
                    navigate("/ar_vr_custom_development");
                  }}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <VideoBox
              className="w-[350px] md:w-[70vw] xl:w-[634px] min-h-[250px]"
              url="https://youtu.be/k_gxBAxf3ag"
            />
          </div>
        </div>
      </section>
      <section className="flex flex-col justify-center py-4 md:fit_wrap xl:py-8 max-md:border max-md:border-y max-md:border-[#2E90F2] max-md:border-x-0">
        <div className="pb-4 md:pb-4 xl:pb-8">
          <h6 className="mb-4 font-bold text-center xl:text-2xl text-xl max-md:text-[#2E90F2]">
            Project Examples
          </h6>
          <div className="flex justify-center">
            <p className="w-[350px] md:w-[612px] xl:w-[966px] text-center xl:text-md max-md:font-light">
              Clients rely on us to bring their unique workforce training
              programs to life by incorporating key engagement strategies, like
              experiential learning, gamification and immersive storytelling.
            </p>
          </div>
          <div className="flex justify-center mt-4">
            <button
              className="transition ease-in-out hover:bg-white hover:text-black px-5 py-2 rounded-lg border border-white"
              onClick={() => {
                setCollapse((prev) => !prev);
              }}
            >
              {collapse ? "Learn More" : "Learn Less"}
            </button>
          </div>
        </div>
        {collapse ? null : (
          <div>
            <Scrollbar
              contentCls="flex md:overflow-x-auto md:space-x-8 md:w-[90vw] xl:w-[80vw] 2xl:w-[1440px] mx-auto md:px-4 pb-6 scrollbar-none max-md:flex-col max-md:space-y-5"
              scrollbarCls="scrollbar_box"
            >
              <FitItem
                icon={product_img}
                title="Product Visualization"
                description="A forward-thinking Defense manufacturer requested rapid prototyping from ARSOME Technology to develop several mixed-reality experiences to enable employees to visualize the parts and pieces of a final product."
                url="https://youtu.be/88s5QgZknjg"
              />
              <FitItem
                icon={maintence_trouble}
                title="Maintenance & Troubleshooting"
                description="The DoD chose ARSOME Technology to develop AR maintenance and part identification training so personnel can learn different components and pieces of heavy equipment, improving their readiness for part replacement in the field."
                url=""
              />
              <FitItem
                icon={driving_training}
                title="Driver Training"
                description="ARSOME Technology developed a highly advanced VR driver training program with immersive simulations for companies to assess driver risk, like abiding by speed limits or unloading goods in a busy city intersection with a colleague."
                url="https://youtu.be/79F_ihsNwOE"
              />
              <FitItem
                icon={damage_img}
                title="Damage & Claims Analysis"
                description="A leading insurance company hired ARSOME Technology to create an AR app to help claim adjusters analyze property damage by simulating car accidents, kitchen fires, and basement mold; making training more engaging and impactful."
                url="https://www.youtube.com/watch?v=Qjjdri1PYcc"
              />

              <FitItem
                icon={simulate_img}
                title="Drone Training"
                description="A defense company used drones to assess roof damage of heavy vehicles returning from combat. ARSOME Technology developed an AR drone training program to get operators experienced with its controls and features prior to in-field deployment."
                url="https://www.youtube.com/watch?v=SLQCRoMNpz8"
              />
            </Scrollbar>
            <div className="flex justify-center mt-4 space-x-6 md:mt-8">
              <button
                className="transition ease-in-out hover:bg-[#383231] hover:text-white hover:border-[#383231] border border-white px-5 py-2 rounded-lg max-md:border-[#2E90F2] max-md:text-white max-md:bg-[#2E90F2]"
                onClick={() => {
                  setShowCase(true);
                }}
              >
                Case Study
              </button>
              <button
                className="transition ease-in-out hover:bg-[#383231] hover:text-white hover:border-[#383231] px-5 py-2 rounded-lg border border-[#2E90F2] text-[#2E90F2] bg-white"
                onClick={() => {
                  navigate("/ar_vr_custom_development");
                }}
              >
                Book Demo
              </button>
            </div>
          </div>
        )}
      </section>
      <section className="py-5 fit_wrap xl:py-8 md:px-4 ">
        <div className="flex justify-center">
          <div className="xl:w-[602px] md:w-[542px] bg-white rounded-lg p-4 xl:p-8 md:mx-3 max-md:w-[344px]">
            <div className="grid gap-3">
              <h6 className="text-[#383231] font-bold md:text-2xl">
                Contact us to learn more about our tailor-made AR/VR solutions.
              </h6>
              <div>
                <input
                  placeholder="Full Name*"
                  className="w-full p-2 border border-t-0 border-b-2 border-l-0 border-r-0 border-[#4387EB] text-my-gray"
                />
              </div>
              <div>
                <input
                  placeholder="Phone Number (optional)"
                  className="w-full p-2 border border-t-0 border-b-2 border-l-0 border-r-0 border-[#4387EB] text-my-gray"
                />
              </div>
              <div>
                <input
                  placeholder="Email*"
                  className="w-full p-2 border border-t-0 border-b-2 border-l-0 border-r-0 border-[#4387EB] text-my-gray"
                />
              </div>
              <div>
                <div className="block mb-2">
                  <Label value="Note*" />
                </div>
                <textarea
                  placeholder=""
                  rows={3}
                  className="w-full p-2 border-2 rounded-md border-[#4387EB] text-my-gray"
                />
              </div>
              <div className="max-md:flex max-md:justify-center">
                <button className="px-5 py-2 rounded-md bg-[#4387EB] transition hover:ease-linear	hover:bg-[#383231]">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showCase}
        onClose={() => setShowCase(false)}
        dismissible
        theme={{
          content: {
            base: "flex h-full w-full p-4 flex items-center md:h-auto",
            inner:
              "rounded-lg bg-white shadow dark:bg-gray-700 max-md:w-full md:w-[90vw]",
          },
        }}
      >
        <Modal.Header className="pb-0 border-none"></Modal.Header>
        <Modal.Body className="space-y-4">
          <div className="space-y-5">
            <h6 className="text-[#2E90F2] font-semibold">
              Let's get you the right case study. Tell us a little about your
              training needs.
            </h6>
            <div>
              <div className="relative xl:h-[200px] md:h-[220px] max-md:h-[250px]">
                <Textarea
                  className={`block absolute rounded-lg border-[#2C8AE8] border xl:h-[200px] md:h-[220px] max-md:h-[250px] z-10 resize-none ${
                    nonEmpty ? "bg-white" : "bg-transparent"
                  }`}
                  {...register("content", { required: true })}
                  rows={7}
                />
                {!nonEmpty && (
                  <div className="text-[#92A8A4] space-y-2 text-sm absolute px-3 py-2 rounded-lg border-[#2C8AE8] border xl:h-[200px] md:h-[220px] max-md:h-[250px]">
                    <ul className="space-y-3 list-disc list-outside ps-3">
                      <li>
                        Do employees need to learn how to operate heavy
                        equipment for improved readiness?
                      </li>
                      <li>
                        Do you need to simulate workforce challenges,
                        situations, and hazards for better workforce safety?
                      </li>
                      <li>
                        Do employees need to learn the best practices for
                        maintaining or replacing malfunctioning parts or pieces?
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <TextInput
              type="text"
              placeholder="Enter your email address"
              {...register("email", { required: true })}
            />
            <div>
              <button
                className="w-full py-2 font-bold text-white rounded-md tracker_select_bar"
                disabled={!isValid}
                onClick={() => sendInquiry()}
              >
                {isSending && <Spinner size="sm" className="me-2" />}
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <InquiryResultModal
        show={showMessage}
        success={isSuccess}
        closeAction={() => setShowMessage(false)}
      />
    </div>
  );
};
