'use client';
interface Props {
  title: string;
  description: string;
}
export const CenterDesc = (props: Props) => {
  const { title, description } = props;
  return (
    <div className="p-8 space-y-4 md:center_desc_wrap max-md:border max-md:border-y max-md:border-[#2E90F2] max-md:border-x-0">
      <h6 className=" text-md md:text-xl xl:text-2xl font-bold text-center text-[#383231] md:text-white max-md:text-xl max-md:text-start">
        {title}
      </h6>
      <div className="flex justify-center">
        <p className="md:px-8 xl:px-0 text-center md:text-md xl:text-base xl:w-[950px] max-md:text-[#383231] max-md:font-light max-md:text-start">
          {description}
        </p>
      </div>
    </div>
  );
};
